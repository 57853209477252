import React from 'react';
import { Box } from 'src/components/Box';
import { CheckListItem } from 'src/components/CheckListItem';
import { Flex } from 'src/components/Flex';
import { Grid } from 'src/components/Grid';
import { Heading } from 'src/components/Heading';
import { MediaSectionContent } from 'src/components/MediaSection';
import { Paragraph } from 'src/components/Paragraph';
import { Section } from 'src/components/Section';
import { ThemeProvider } from 'src/components/ThemeProvider';

export const CenteredSectionTitle = ({ title, children = null }) => {
    return (
        <Flex
            direction="column"
            align={{
                _: 'flex-start',
                xl: 'center',
            }}
            mb="spacing-xl"
        >
            <Heading kind="h2">{title}</Heading>
            {children}
        </Flex>
    );
};

export const WhatWeOffer = props => {
    return (
        <ThemeProvider defaultMode="dark">
            <Section vertical="lg" wrap {...props}>
                <CenteredSectionTitle title="What we offer" />

                <Grid
                    columns={[1, null, 2]}
                    spacing={['spacing-xl', null, '80px']}
                >
                    <MediaSectionContent title="Software updates, upgrades and routine maintenance">
                        <Paragraph color="bodyText">
                            Rest at ease while we handle the details of
                            maintaining a community and 3rd party software.
                        </Paragraph>
                        <Box pt="spacing" as="ul">
                            {[
                                {
                                    title:
                                        'Consistent checks to ensure sites are optimized and running properly',
                                },
                                {
                                    title: 'Small theme changes and tweaks',
                                },
                                {
                                    title:
                                        'Upgrade of platform software when new versions are released',
                                },
                                {
                                    title:
                                        'Upgrade of 3rd party software when new versions are released',
                                },
                                {
                                    title: 'Small bug fixes',
                                },
                            ].map(highlight => (
                                <CheckListItem
                                    key={highlight.title}
                                    title={highlight.title}
                                    as="li"
                                />
                            ))}
                        </Box>
                    </MediaSectionContent>

                    <MediaSectionContent title="Intricate development requests">
                        <Paragraph color="bodyText">
                            Continually enhance your community with dedicated
                            hours for development with our team available for
                            meetings and consultation throughout the month.
                        </Paragraph>
                        <Box pt="spacing">
                            {[
                                {
                                    title: 'Feature development',
                                },
                                {
                                    title:
                                        'Troubleshooting and resolving any significant issues',
                                },
                                {
                                    title:
                                        'Quarterly audit of sites and implementing approved optimizations',
                                },
                                {
                                    title: 'Consultation and strategy',
                                },
                                {
                                    title: 'New theme development',
                                },
                            ].map(highlight => (
                                <CheckListItem
                                    key={highlight.title}
                                    title={highlight.title}
                                />
                            ))}
                        </Box>
                    </MediaSectionContent>
                </Grid>
            </Section>
        </ThemeProvider>
    );
};
