import { graphql, useStaticQuery } from 'gatsby';

export const usePackageAddonData = () => {
    const data = useStaticQuery(packageAddonQuery);

    return packageAddonList.map(item => ({
        ...item,
        icon: data && data[item.id]?.childImageSharp?.fixed,
    }));
};

const packageAddonList = [
    {
        heading: 'achievements',
        content:
            'Encourage more engagement and beneficial activities with a robust and customizable reward system.        ',
        id: 'achievement',
    },
    {
        heading: 'leaderboards',
        content:
            'Highlight members for their high-level of activity through a community-wide leaderboard.',
        id: 'leaderboard',
    },
    {
        heading: 'complete account',
        content:
            'Help onboard new members by showing progress as they complete tasks to create the ideal profile.        ',
        id: 'completeaccount',
    },
    {
        heading: 'site tour',
        content:
            'On first login, guide new users with helpful prompts and a tour of the site to understand features.',
        id: 'sitetour',
    },
    {
        heading: 'shopify',
        content:
            'Track orders and customers within Shopify and award users for purchases.        ',
        id: 'shopify',
    },
    {
        heading: 'feeds',
        content:
            'Easily promote content and filter dedicated feeds like hot (trending), questions, latest activity, and more.',
        id: 'feeds',
    },
    {
        heading: 'google perspective',
        content:
            'Share toxicity rating in real-time when posting, block certain posts, and view content based on the toxicity rating.',
        id: 'googleperspective',
    },
    {
        heading: 'mailchimp',
        content:
            'Have users more easily added to mailing lists with tags created in XenForo synced with Mailchimp.        ',
        id: 'mailchimp',
    },
    {
        heading: 'content management plus',
        content:
            'Admins and mods can improve SEO, and suggest similar content to users to avoid duplicate postings.',
        id: 'contentmanagmentplus',
    },
    {
        heading: 'guest prompts',
        content:
            'Prompt guests to login when they try to engage like creating a thread or responding to a discussion.',
        id: 'guestprompts',
    },
    {
        heading: 'a/b testing',
        content:
            'Test features and receive feedback by placing users in specific user groups  based on criteria.',
        id: 'abtesting',
    },
    {
        heading: 'ac.ui',
        content:
            'A meticulously designed modern interface built with our tried-and-true theme framework.',
        id: 'uix',
    },
    {
        heading: 'lessons',
        content:
            'Have a lesson structure that supports classes, difficulty levels, and user progress.',
        id: 'lessons',
    },
    {
        heading: 'thread types plus',
        content:
            'Add more thread types such as media, recipe, report, support, and wiki.       ',
        id: 'audentiocommunity',
    },
    {
        heading: 'user group marking',
        content:
            'Create unique post bit styling to highlight certain user groups like premium members.',
        id: 'usergroup',
    },
    {
        heading: 'emoji',
        content:
            'Give members in specified user criteria access to custom emojis only accessible to them.',
        id: 'emoji',
    },
    {
        heading: 'image optimizer',
        content:
            'Automatically optimize any image uploaded to improve page speed and save on storage and bandwidth.',
        id: 'imageoptimizer',
    },
    {
        heading: 'monetize',
        content:
            'Generate revenue by setting up a paywall, associating sponsors with forums, and user upgrade options.',
        id: 'monetize',
    },
    {
        heading: 'page optimizer',
        content:
            'Improve performance by using WebP for images and minifying CSS, JS, and HTML.',
        id: 'pageoptimizer',
    },
    {
        heading: 'reaction plus',
        content:
            'Create new reactions, choose who can use reactions, what forums they can be used in, and more.',
        id: 'reactionplus',
    },
    {
        heading: 'style switch',
        content:
            'Depending on the set user preference in their OS, the theme will transition between light and dark mode.',
        id: 'styleswitch',
    },
    {
        heading: 'spotify',
        content:
            'Users can connect their Spotify account and share what they are listening to with others.',
        id: 'spotify',
    },
    {
        heading: 'trending',
        content:
            'Display your most active and relevant content to improve content discovery and engagement.',
        id: 'trending',
    },
    {
        heading: '40+ addons and themes',
        content: 'With the bundle, enjoy our full ThemeHouse product line.',
        id: 'themehouse',
    },
];

const packageAddonQuery = graphql`
    query packageAddonsQuery {
        abtesting: file(
            relativePath: {
                eq: "images/resources/package-addons/ab-testing.png"
            }
        ) {
            childImageSharp {
                fixed(width: 80, height: 80) {
                    ...GatsbyImageSharpFixed_withWebp
                }
            }
        }
        achievement: file(
            relativePath: {
                eq: "images/resources/package-addons/achievement.png"
            }
        ) {
            childImageSharp {
                fixed(width: 80, height: 80) {
                    ...GatsbyImageSharpFixed_withWebp
                }
            }
        }
        audentiocommunity: file(
            relativePath: {
                eq: "images/resources/package-addons/thread-types.png"
            }
        ) {
            childImageSharp {
                fixed(width: 80, height: 80) {
                    ...GatsbyImageSharpFixed_withWebp
                }
            }
        }
        carepackage: file(
            relativePath: {
                eq: "images/resources/package-addons/carepackage.png"
            }
        ) {
            childImageSharp {
                fixed(width: 80, height: 80) {
                    ...GatsbyImageSharpFixed_withWebp
                }
            }
        }
        completeaccount: file(
            relativePath: {
                eq: "images/resources/package-addons/complete-account.png"
            }
        ) {
            childImageSharp {
                fixed(width: 80, height: 80) {
                    ...GatsbyImageSharpFixed_withWebp
                }
            }
        }
        feeds: file(
            relativePath: { eq: "images/resources/package-addons/feeds.png" }
        ) {
            childImageSharp {
                fixed(width: 80, height: 80) {
                    ...GatsbyImageSharpFixed_withWebp
                }
            }
        }
        googleperspective: file(
            relativePath: {
                eq: "images/resources/package-addons/google-perspective.png"
            }
        ) {
            childImageSharp {
                fixed(width: 80, height: 80) {
                    ...GatsbyImageSharpFixed_withWebp
                }
            }
        }
        guestprompts: file(
            relativePath: {
                eq: "images/resources/package-addons/guest-prompts.png"
            }
        ) {
            childImageSharp {
                fixed(width: 80, height: 80) {
                    ...GatsbyImageSharpFixed_withWebp
                }
            }
        }
        leaderboard: file(
            relativePath: {
                eq: "images/resources/package-addons/leaderboard.png"
            }
        ) {
            childImageSharp {
                fixed(width: 80, height: 80) {
                    ...GatsbyImageSharpFixed_withWebp
                }
            }
        }
        lessons: file(
            relativePath: { eq: "images/resources/package-addons/lessons.png" }
        ) {
            childImageSharp {
                fixed(width: 80, height: 80) {
                    ...GatsbyImageSharpFixed_withWebp
                }
            }
        }
        mailchimp: file(
            relativePath: {
                eq: "images/resources/package-addons/mailchimp.png"
            }
        ) {
            childImageSharp {
                fixed(width: 80, height: 80) {
                    ...GatsbyImageSharpFixed_withWebp
                }
            }
        }
        shopify: file(
            relativePath: { eq: "images/resources/package-addons/shopify.png" }
        ) {
            childImageSharp {
                fixed(width: 80, height: 80) {
                    ...GatsbyImageSharpFixed_withWebp
                }
            }
        }
        sitetour: file(
            relativePath: {
                eq: "images/resources/package-addons/site-tour.png"
            }
        ) {
            childImageSharp {
                fixed(width: 80, height: 80) {
                    ...GatsbyImageSharpFixed_withWebp
                }
            }
        }
        themehouse: file(
            relativePath: {
                eq: "images/resources/package-addons/themehouse.png"
            }
        ) {
            childImageSharp {
                fixed(width: 80, height: 80) {
                    ...GatsbyImageSharpFixed_withWebp
                }
            }
        }
        uix: file(
            relativePath: { eq: "images/resources/package-addons/acui.png" }
        ) {
            childImageSharp {
                fixed(width: 80, height: 80) {
                    ...GatsbyImageSharpFixed_withWebp
                }
            }
        }
        usergroup: file(
            relativePath: {
                eq: "images/resources/package-addons/user-group.png"
            }
        ) {
            childImageSharp {
                fixed(width: 80, height: 80) {
                    ...GatsbyImageSharpFixed_withWebp
                }
            }
        }
        contentmanagmentplus: file(
            relativePath: {
                eq: "images/resources/package-addons/content-management-plus.png"
            }
        ) {
            childImageSharp {
                fixed(width: 80, height: 80) {
                    ...GatsbyImageSharpFixed_withWebp
                }
            }
        }

        emoji: file(
            relativePath: { eq: "images/resources/package-addons/emoji.png" }
        ) {
            childImageSharp {
                fixed(width: 80, height: 80) {
                    ...GatsbyImageSharpFixed_withWebp
                }
            }
        }

        imageoptimizer: file(
            relativePath: {
                eq: "images/resources/package-addons/image-optimizer.png"
            }
        ) {
            childImageSharp {
                fixed(width: 80, height: 80) {
                    ...GatsbyImageSharpFixed_withWebp
                }
            }
        }

        monetize: file(
            relativePath: { eq: "images/resources/package-addons/monetize.png" }
        ) {
            childImageSharp {
                fixed(width: 80, height: 80) {
                    ...GatsbyImageSharpFixed_withWebp
                }
            }
        }

        pageoptimizer: file(
            relativePath: {
                eq: "images/resources/package-addons/page-optimizer.png"
            }
        ) {
            childImageSharp {
                fixed(width: 80, height: 80) {
                    ...GatsbyImageSharpFixed_withWebp
                }
            }
        }

        reactionplus: file(
            relativePath: {
                eq: "images/resources/package-addons/reaction-plus.png"
            }
        ) {
            childImageSharp {
                fixed(width: 80, height: 80) {
                    ...GatsbyImageSharpFixed_withWebp
                }
            }
        }

        styleswitch: file(
            relativePath: {
                eq: "images/resources/package-addons/style-switch.png"
            }
        ) {
            childImageSharp {
                fixed(width: 80, height: 80) {
                    ...GatsbyImageSharpFixed_withWebp
                }
            }
        }

        spotify: file(
            relativePath: { eq: "images/resources/package-addons/spotify.png" }
        ) {
            childImageSharp {
                fixed(width: 80, height: 80) {
                    ...GatsbyImageSharpFixed_withWebp
                }
            }
        }

        trending: file(
            relativePath: { eq: "images/resources/package-addons/trending.png" }
        ) {
            childImageSharp {
                fixed(width: 80, height: 80) {
                    ...GatsbyImageSharpFixed_withWebp
                }
            }
        }
    }
`;
