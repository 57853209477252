import { Button } from 'components/Button';
import { Flex } from 'components/Flex';
import React from 'react';
import { Box } from '../Box';
import { Heading } from '../Heading';
import { Paragraph } from '../Paragraph';
import { SectionHeading } from './SectionHeader.styled';

export const SectionHeader = ({
    title,
    description = null,
    subtitle = null,
    showContact = true,
    customButton = {},
    sectionHeaderProps = {},
    ...props
}) => {
    return (
        <Box {...props}>
            <Flex
                direction={{ _: 'column', md: 'row' }}
                justifyContent="space-between"
                {...sectionHeaderProps}
            >
                <Box>
                    <SectionHeading
                        kind="h4"
                        pr={{ _: '0', md: 'spacing-lg' }}
                        my="spacing-sm"
                    >
                        {title}
                    </SectionHeading>

                    {subtitle && (
                        <Heading kind="h4" fontWeight="normal" mt="spacing-sm">
                            {subtitle}
                        </Heading>
                    )}

                    {description && (
                        // max-width taken from current media section grid sizing
                        <Paragraph
                            maxWidth={{ _: '100%', md: '50%', xl: '62%' }}
                            mt="spacing-sm"
                        >
                            {description}
                        </Paragraph>
                    )}
                </Box>

                {showContact && (
                    <Button
                        href="/contact"
                        maxWidth="100px"
                        my={{ _: 'spacing-lg', md: 0 }}
                    >
                        Contact Us
                    </Button>
                )}
                {customButton.text && (
                    <Button
                        href={customButton.href || '#'}
                        external
                        maxWidth="100px"
                        my={customButton.my}
                    >
                        {customButton.text}
                    </Button>
                )}
            </Flex>
        </Box>
    );
};
