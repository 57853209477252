import { Flex } from 'components/Flex';
import { Heading } from 'components/Heading';
import CheckCircleIcon from 'mdi-react/CheckCircleIcon';
import CircleIcon from 'mdi-react/CircleIcon';
import React from 'react';
import { Box } from '../Box';

export function CheckListItem({
    children = null,
    title,
    kind = 'h6',
    headingStyle = {},
    circleIcon = false,
    ...props
}) {
    return (
        <Box py="spacing" {...props} color="faintText">
            <Flex direction="row">
                <Box mr="spacing" color="boldText" lineHeight={1.3} pt="1px" aria-hidden="true">
                    {circleIcon ? (
                        <CircleIcon size={10} />
                    ) : (
                        <CheckCircleIcon size={20} />
                    )}
                </Box>
                <div>
                    <Heading
                        kind={kind}
                        mb="spacing-sm"
                        fontWeight={500}
                        {...headingStyle}
                    >
                        {title}
                    </Heading>
                    {children && (
                        <Box lineHeight="normal" as="span" color="bodyText">
                            {children}
                        </Box>
                    )}
                </div>
            </Flex>
        </Box>
    );
}
