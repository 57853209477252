import { motion } from 'framer-motion';
import Img from 'gatsby-image';
import React from 'react';
import { moveTransition } from 'src/components/AnimatedContainer';
import { Box } from 'src/components/Box';
import { Button } from 'src/components/Button';
import { Flex } from 'src/components/Flex';
import { Grid } from 'src/components/Grid';
import { IO } from 'src/components/IO';
import { MediaSectionContent } from 'src/components/MediaSection';
import { Paragraph } from 'src/components/Paragraph';
import { useTheme } from 'src/components/ThemeProvider';
import { useWindowResize } from 'src/helpers/useWindowResize';
import { BounceContainer } from '../../streaming/VideoHeroSection/BounceAvatar';
import { THHeroContent } from './THHeroContent';
import { heroIconSize, spacing, useHeroAddonData } from './useHeroData';

export const ThemeHouseHero = () => {
    const iconList = useHeroAddonData();
    const { breakpoint } = useTheme();
    const { windowWidth } = useWindowResize();

    // unless otherwise specified, limit animation to desktop
    const allowAnimation = false; // windowWidth > breakpoint.stripped.xl + 100;
    const largeHeroHeight = 3 * heroIconSize + 2 * spacing;

    if (allowAnimation) {
        return (
            <Box py={250}>
                <Box pos="absolute" top={0} left={0} w="100%" h="100%">
                    {iconList.map(icon => (
                        <motion.div
                            layoutId={`card-container-${icon.id}`}
                            key={icon.id}
                            style={{
                                position: 'absolute',
                                left: icon.left,
                                right: icon.right,
                                top: icon.top,

                                // ...rest,
                            }}
                            initial={{
                                scale: 1,
                            }}
                            animate={{
                                scale: icon.scale,
                            }}
                            transition={moveTransition}
                        >
                            <BounceContainer
                                pos="relative"
                                w={heroIconSize}
                                h={heroIconSize}
                                rounded="radius"
                                overflow="hidden"
                            >
                                <Img
                                    alt=""
                                    style={{
                                        width: '200px',
                                        // height: '100%',
                                    }}
                                    loading="eager"
                                    fluid={icon.icon}
                                />
                            </BounceContainer>
                        </motion.div>
                    ))}
                </Box>
                <Grid
                    columns={[1, null, null, 2, 1]}
                    maxW={{ _: 'unset', xl: 400 }}
                    spacing="spacing-xl"
                    mx="auto"
                    z={2}
                    pos="relative"
                >
                    <Box rounded="radius">
                        <MediaSectionContent
                            title="We develop for the many"
                            kind="h2"
                            textAlign={{
                                _: 'left',
                                xl: 'center',
                            }}
                            isCentered
                        >
                            <Paragraph color="boldText">
                                We infuse the same passion and creative spirit
                                we have about community into our suite of
                                products that include a theme framework, XenForo
                                to WordPress bridge, and a collection of add-ons
                                focused on monetization, gamification, user
                                engagement, and more.
                            </Paragraph>
                            <Flex
                                justify={{
                                    _: 'flex-start',
                                    xl: 'center',
                                }}
                                mt="spacing-lg"
                            >
                                <Button
                                    href="/contact?source=resources"
                                    fill="black"
                                >
                                    Contact us
                                </Button>
                            </Flex>
                        </MediaSectionContent>
                    </Box>
                </Grid>
            </Box>
        );
    }

    return (
        <Box>
            <Grid
                columns={6}
                spacing="spacing"
                key="grid_mobile"
                mb={{
                    _: 'spacing-lg',
                    sm: 'spacing-xl',
                }}
            >
                {iconList?.slice(0, 6).map(({ id, icon }, index) => {
                    return (
                        <Flex
                            key={id}
                            direction="column"
                            justify="flex-end"
                            w="100%"
                            minW={75}
                        >
                            <Box
                                pos="relative"
                                rounded="radius"
                                overflow="hidden"
                                mb="spacing"
                            >
                                <Img
                                    alt=""
                                    style={{
                                        width: 'auto',
                                        height: '100%',
                                    }}
                                    loading="eager"
                                    fluid={icon}
                                />
                            </Box>
                            <Box
                                pos="relative"
                                rounded="radius"
                                overflow="hidden"
                            >
                                <Img
                                    alt=""
                                    style={{
                                        width: 'auto',
                                        height: '100%',
                                    }}
                                    loading="eager"
                                    fluid={iconList[index + 6]?.icon}
                                />
                            </Box>
                        </Flex>
                    );
                })}
            </Grid>

            <Grid
                columns={[1, null, null, 2, 1]}
                maxW={{ _: 'unset', xl: 400 }}
                spacing="spacing-xl"
                mx="auto"
            >
                <Box rounded="radius">
                    <MediaSectionContent
                        title="We develop for the many"
                        kind="h2"
                        textAlign={{
                            _: 'left',
                            xl: 'center',
                        }}
                        isCentered
                    >
                        <Paragraph color="boldText">
                            We infuse the same passion and creative spirit we
                            have about community into our suite of products that
                            include a theme framework, XenForo to WordPress
                            bridge, and a collection of add-ons focused on
                            monetization, gamification, user engagement, and
                            more.
                        </Paragraph>
                        <Flex
                            justify={{
                                _: 'flex-start',
                                xl: 'center',
                            }}
                            mt="spacing-lg"
                        >
                            <Button
                                href="/contact?source=resources"
                                fill="black"
                            >
                                Contact us
                            </Button>
                        </Flex>
                    </MediaSectionContent>
                </Box>
            </Grid>
        </Box>
    );

    // desktop animated grid
    return (
        <IO rootMargin="-50%">
            {({ isVisible }) => {
                return (
                    <Flex
                        justify="center"
                        align="center"
                        h={{
                            _: 'unset',
                            xl: largeHeroHeight,
                        }}
                    >
                        <>
                            <Box
                                opacity={{
                                    _: 0.2,
                                    xl: 1,
                                }}
                            >
                                {/* <Grid
                                    columns={4}
                                    spacing="spacing-lg"
                                    key="grid"
                                >
                                    {iconList?.map(({ id, icon }) => {
                                        return (
                                            <motion.div
                                                key={id}
                                                layoutId={`card-container-${id}`}
                                                transition={moveTransition}
                                            >
                                                <Box
                                                    pos="relative"
                                                    w={heroIconSize}
                                                    h={heroIconSize}
                                                    rounded="radius"
                                                    overflow="hidden"
                                                >
                                                    <Img
                                                        alt=""
                                                        style={{
                                                            width: 'auto',
                                                            height: '100%',
                                                        }}
                                                        loading="eager"
                                                        fluid={icon}
                                                    />
                                                </Box>
                                            </motion.div>
                                        );
                                    })}
                                </Grid> */}

                                <Box key="expanded_cards">
                                    {iconList.map(
                                        ({ id, icon, scale = 1, ...rest }) => {
                                            return (
                                                <motion.div
                                                    layoutId={`card-container-${id}`}
                                                    key={id}
                                                    style={{
                                                        position: 'absolute',
                                                        ...rest,
                                                    }}
                                                    initial={{
                                                        scale: 1,
                                                    }}
                                                    animate={{
                                                        scale,
                                                    }}
                                                    transition={moveTransition}
                                                >
                                                    <BounceContainer
                                                        pos="relative"
                                                        w={heroIconSize}
                                                        h={heroIconSize}
                                                        rounded="radius"
                                                        overflow="hidden"
                                                    >
                                                        <Img
                                                            alt=""
                                                            style={{
                                                                width: 'auto',
                                                                height: '100%',
                                                            }}
                                                            loading="eager"
                                                            fluid={icon}
                                                        />
                                                    </BounceContainer>
                                                </motion.div>
                                            );
                                        }
                                    )}
                                </Box>
                            </Box>

                            <THHeroContent isAnimated />
                        </>
                    </Flex>
                );
            }}
        </IO>
    );
};
