import { graphql, useStaticQuery } from 'gatsby';

export const useHeroAddonData = () => {
    const data = useStaticQuery(heroAddonsQuery);

    return heroIconList.map(item => ({
        ...item,
        icon: data && data[item.id]?.childImageSharp?.fluid,
    }));
};

export const spacing = 60;
export const heroIconSize = 200;

const heroIconList = [
    {
        id: 'leaderboard',
        top: spacing,
        left: -10,
    },
    {
        id: 'usergroup',
        top: 1.5 * spacing,
        left: heroIconSize - 10 + spacing,
    },
    {
        id: 'ac',
        top: 1.5 * spacing,
        right: heroIconSize - 10 + spacing,
    },
    {
        id: 'mailchimp',
        top: spacing,
        right: -10,
    },
    {
        id: 'pageoptimizer',
        top: 2.2 * spacing + heroIconSize,
        left: -spacing,
        scale: 1.2,
    },
    {
        id: 'completeaccount',
        top: 2 * spacing + heroIconSize,
        left: heroIconSize,
        scale: 0.75,
    },
    {
        id: 'achievements',
        top: 2 * spacing + heroIconSize,
        right: heroIconSize,
        scale: 0.75,
    },
    {
        id: 'feeds',
        top: 2.2 * spacing + heroIconSize,
        right: -spacing,
        scale: 1.2,
    },
    {
        id: 'shopify',
        top: 3.3 * spacing + 2 * heroIconSize,
        left: 0.5 * spacing,
    },
    {
        id: 'monetize',
        top: 2.5 * spacing + 2 * heroIconSize,
        left: 1.5 * spacing + heroIconSize,
    },
    {
        id: 'imageoptimizer',
        top: 2.5 * spacing + 2 * heroIconSize,
        right: 1.5 * spacing + heroIconSize,
    },

    {
        id: 'metrics',
        top: 3.3 * spacing + 2 * heroIconSize,
        right: 0.5 * spacing,
    },
];

const heroAddonsQuery = graphql`
    query heroAddonsQuery {
        ac: file(relativePath: { eq: "images/resources/hero-addons/ac.png" }) {
            childImageSharp {
                fluid(maxWidth: 400) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        achievements: file(
            relativePath: {
                eq: "images/resources/hero-addons/achievements.png"
            }
        ) {
            childImageSharp {
                fluid(maxWidth: 400) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        completeaccount: file(
            relativePath: {
                eq: "images/resources/hero-addons/complete-account.png"
            }
        ) {
            childImageSharp {
                fluid(maxWidth: 400) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        imageoptimizer: file(
            relativePath: {
                eq: "images/resources/hero-addons/img-optimizer.png"
            }
        ) {
            childImageSharp {
                fluid(maxWidth: 400) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        feeds: file(
            relativePath: { eq: "images/resources/hero-addons/feeds.png" }
        ) {
            childImageSharp {
                fluid(maxWidth: 400) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        pageoptimizer: file(
            relativePath: {
                eq: "images/resources/hero-addons/page-optimizer.png"
            }
        ) {
            childImageSharp {
                fluid(maxWidth: 400) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        leaderboard: file(
            relativePath: { eq: "images/resources/hero-addons/leaderboard.png" }
        ) {
            childImageSharp {
                fluid(maxWidth: 400) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        mailchimp: file(
            relativePath: { eq: "images/resources/hero-addons/mailchimp.png" }
        ) {
            childImageSharp {
                fluid(maxWidth: 400) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        metrics: file(
            relativePath: { eq: "images/resources/hero-addons/metrics.png" }
        ) {
            childImageSharp {
                fluid(maxWidth: 400) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        monetize: file(
            relativePath: { eq: "images/resources/hero-addons/monetize.png" }
        ) {
            childImageSharp {
                fluid(maxWidth: 400) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        shopify: file(
            relativePath: { eq: "images/resources/hero-addons/shopify.png" }
        ) {
            childImageSharp {
                fluid(maxWidth: 400) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        usergroup: file(
            relativePath: { eq: "images/resources/hero-addons/user-group.png" }
        ) {
            childImageSharp {
                fluid(maxWidth: 400) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
`;
