/** @jsx jsx */
import { css, jsx, keyframes } from '@emotion/core';
import Img from 'gatsby-image';
import { useMemo } from 'react';
import { Box } from 'src/components/Box';
import { Flex } from 'src/components/Flex';

const bounce = keyframes`
  0% {
        transform: translateY(0);
    }

    25% {
        transform: translateY(7px);
    }

    50% {
        transform: translateY(0);
    }

    75% {
        transform: translateY(-7px);
    }

    100% {
        transform: translateY(0);
    }
`;

const bounceProps = delay => ({
    css: css`
        animation: ${bounce} 8s linear ${delay} infinite;
        img {
            object-fit: unset;
        }
    `,
});

export const BounceContainer = ({ children, ...props }) => {
    const randomDelay = useMemo(() => Math.random() * 6, []);
    return (
        <Box {...bounceProps(`${randomDelay}s`)} h="100%" flex={1} {...props}>
            {children}
        </Box>
    );
};

export const BounceAvatar = ({ prof, ...rest }) => {
    const randomLeftOffset = useMemo(() => Math.random() * 50, []);
    const randomTopOffset = useMemo(() => Math.random() * 50, []);

    return (
        <Flex
            align="center"
            position="relative"
            right={0}
            flex={1}
            h="100%"
            {...rest}
            transform={`translate(${randomLeftOffset}%, ${randomTopOffset}%)`}
        >
            <BounceContainer>
                <Img
                    alt=""
                    style={{ width: '100%', maxWidth: '175px', height: 'auto' }}
                    fluid={prof}
                />
            </BounceContainer>
        </Flex>
    );
};
