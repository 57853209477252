import { Box } from 'components/Box';
import { Flex } from 'components/Flex';
import { Heading } from 'components/Heading';
import { Paragraph } from 'components/Paragraph';
import Img from 'gatsby-image';
import { Anchor } from '../Anchor';
import { Grid } from '../Grid';
import { Section } from '../Section';
import { SectionHeader } from '../SectionHeader';

export const FeatureGrid = ({ children, offset = false, center, ...props }) => (
    <Grid
        columns={[1, 2, null, 3]}
        spacing={{
            _: 'spacing-lg',
            lg: 'spacing-xl',
        }}
        ml={{
            _: 0,
            lg: offset ? 'spacing-xl' : null,
        }}
        pl={{
            _: 0,
            lg: offset ? 'spacing-xl' : null,
        }}
        mx={{
            _: 0,
            lg: center ? 'spacing-xl' : null,
        }}
        px={{
            _: 0,
            lg: center ? 'spacing-xl' : null,
        }}
        {...props}
    >
        {children}
    </Grid>
);

interface FeatureItemProps {
    heading: string;
    content: string;
    color?: string;
    href?: string;
    number?: number;
    icon?: any;
    image?: any;
    imageLink?: string;
    iconRadius?: string;
}

export const FeatureItem = ({
    heading,
    image,
    content,
    color = null,
    href = null,
    number = null,
    icon = null,
    iconRadius,
    ...props
}: FeatureItemProps) => {
    return (
        <Flex w="100%" {...props}>
            {number && (
                <Paragraph
                    color="faintText"
                    p={{
                        _: 0,
                        lg: 'spacing-xs',
                    }}
                    mr={{ _: 'spacing-lg' }}
                    h={22}
                    letter-spacing={0}
                    lineHeight="22px"
                    textAlign="right"
                >
                    {number}
                </Paragraph>
            )}
            {icon && (
                <Box
                    w={[55, null, 70]}
                    minW={[55, null, 70]}
                    h={[55, null, 70]}
                    pr="spacing-lg"
                >
                    {icon && (
                        <Img
                            alt=""
                            style={{
                                width: '100%',
                                height: '100%',
                                borderRadius: iconRadius ? iconRadius : null,
                            }}
                            loading="eager"
                            fixed={icon}
                        />
                    )}
                </Box>
            )}

            <Box w="100%">
                {image && (
                    <Box py="spacing-lg">
                        <Anchor external href={href ? href : '#'}>
                            <Img
                                style={{
                                    width: '100%',
                                }}
                                fluid={image}
                                alt={heading}
                            />
                        </Anchor>
                    </Box>
                )}
                <Flex
                    direction="row"
                    alignItems="center"
                    mb={['spacing-sm', null, 'spacing']}
                >
                    <Heading
                        kind="h5"
                        mb={0}
                        flex={1}
                        {...(href
                            ? {
                                  href,
                                  as: 'a',
                                  target: image ? '_blank' : '_self',
                                  textDecoration: image ? 'none' : 'underline',
                              }
                            : {})}
                    >
                        {heading}
                    </Heading>
                    {color && (
                        <Box
                            width="40%"
                            height="8px"
                            rounded="2px"
                            backgroundColor={color}
                        />
                    )}
                </Flex>
                <Paragraph>{content}</Paragraph>
                {image && href && (
                    <Flex justifyContent="flex-start" pt="spacing">
                        <Anchor textDecoration="underline" external href={href}>
                            Read more
                        </Anchor>
                    </Flex>
                )}
            </Box>
        </Flex>
    );
};

export function LandingFeatureGrid({
    title,
    items,
    description = null,
    subtitle = null,
    showContact = true,
    isNumbered = false,
    offset = true,
    center = false,
    customButton,
    iconRadius = null,
    featureGridProps = {},
    sectionHeaderProps = {},
    ...props
}: any) {
    return (
        <Section wrap vertical="lg" {...props}>
            {title && (
                <SectionHeader
                    title={title}
                    subtitle={subtitle}
                    description={description}
                    showContact={showContact}
                    customButton={customButton}
                    sectionHeaderProps={sectionHeaderProps}
                    pb={['spacing-lg', '40px', 'spacing-xl']}
                />
            )}

            {items?.length > 0 && (
                <FeatureGrid
                    offset={offset}
                    center={center}
                    {...featureGridProps}
                >
                    {items.map((method, index) => (
                        <FeatureItem
                            key={index}
                            image={method.image}
                            number={isNumbered ? index : null}
                            heading={method.heading}
                            content={method.content}
                            color={method.color}
                            icon={method.icon}
                            href={method.href}
                            iconRadius={iconRadius}
                        />
                    ))}
                </FeatureGrid>
            )}
        </Section>
    );
}
