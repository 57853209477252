import React from 'react';
import { LandingFeatureGrid } from 'src/components/Landing/LandingFeatureGrid';
import { usePackageAddonData } from './usePackageAddonData';

export const PackageAddons = props => {
    const addonList = usePackageAddonData();

    return (
        <LandingFeatureGrid
            bg="secondaryBackground"
            title="Premium add-ons"
            subtitle="Bundled with support for one annual cost"
            offset={false}
            items={addonList || []}
            mb="0"
            iconRadius="4px"
            {...props}
        />
    );
};
