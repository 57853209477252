import { Layout } from 'components/Layout';
import { graphql } from 'gatsby';
import React from 'react';
import { Box } from 'src/components/Box';
import { Button } from 'src/components/Button';
import { CheckListItem } from 'src/components/CheckListItem';
import { MediaSection, MediaSectionContent } from 'src/components/MediaSection';
import { CenteredSectionTitle } from 'src/components/Methods/maintain/WhatWeOffer';
import { PackageAddons } from 'src/components/Methods/resources/PackageAddons';
import { ThemeHouseHero } from 'src/components/Methods/resources/ThemeHouseHero';
import { ToolCard } from 'src/components/Methods/resources/ToolCard';
import { MethodsLandingHero } from 'src/components/MethodsLandingHero';
import { Paragraph } from 'src/components/Paragraph';
import { Section } from 'src/components/Section';
import { ThemeProvider } from 'src/components/ThemeProvider';

const subnav = [
    {
        id: 'themehouse',
        label: 'Themehouse',
    },
    {
        id: 'engagement',
        label: 'Engagement',
    },
    {
        id: 'monetization',
        label: 'Monetization',
    },
    {
        id: 'gamification',
        label: 'Gamification',
    },
    {
        id: 'wordpress',
        label: 'Wordpress',
    },
    {
        id: 'package',
        label: 'Package',
    },
];

export default function Resources({ data }) {
    return (
        <Layout
            pageTitleFull="Online community tools from Audentio"
            siteDescription="We've built many online community products from forum add-ons and themes to integrations."
            contactForm
            methodSubNavIcon="/methods/resources.svg"
            methodSubNavColor="#F9CA23"
            subnavTitle="Resources"
            subnav={subnav}
            wrapChildren
        >
            <MethodsLandingHero
                title="Robust product line designed specifically for communities"
                video="/videos/methods/resources/hero.mp4"
                videoPoster="/videos/methods/resources/hero-poster.png"
                videoId="methods-resources-hero"
                subtitle="We’ve designed, built, and maintained dozens of tools meant to improve monetization, gamification, user engagement, and more for platforms such as XenForo, WordPress, Discord, and Slack."
            />

            <Section
                vertical="lg"
                wrap
                bg="secondaryBackground"
                position="relative"
                id={subnav[0].id}
            >
                <ThemeHouseHero />
            </Section>

            <Section vertical="lg" wrap bg="primaryBackground">
                <CenteredSectionTitle title="Our favorite tools for community success" />
                <ToolCard
                    id={subnav[1].id}
                    title="Engagement"
                    video="/videos/methods/resources/engagement.mp4"
                    videoId="resources-engagement"
                    background="linear-gradient(270deg, #FDF0E0 0%, #F9E4D8 48.3%, #F8F8F8 100%)"
                    highlights={[
                        {
                            heading: 'feeds',
                            content:
                                'More easily promote content, target certain user groups, paid options to promote, and more.                        ',
                        },
                        {
                            heading: 'upvote and downvote                    ',
                            content:
                                'Voting system highlights relevant responses and best answers.                       ',
                        },
                        {
                            heading: 'ac.ui',
                            content:
                                'Built with our theme framework and customized to our most ideal and cleanest interface yet.                        ',
                        },
                    ]}
                    mb={{
                        _: 'spacing-lg',
                        lg: 'spacing-xl',
                    }}
                >
                    Keep users engaged and coming back regularly.
                </ToolCard>

                <ToolCard
                    id={subnav[2].id}
                    title="Monetization"
                    video="/videos/methods/resources/monetization.mp4"
                    videoId="resources-monetization"
                    background="linear-gradient(270deg, #D9F6F9 0%, #E0F1FD 48.3%, #F8F8F8 100%)"
                    highlights={[
                        {
                            heading: 'shopify integration',
                            content:
                                'Track if the member made a purchase and award achievements based on Shopify data.',
                        },
                        {
                            heading: 'membership models',
                            content:
                                'We’ll help you plan different tiers for premium membership.',
                        },
                        {
                            heading: 'donation campaigns',
                            content:
                                'Crowdfunding allows members of your community to invest in the community itself.',
                        },
                    ]}
                    mb={{
                        _: 'spacing-lg',
                        lg: 'spacing-xl',
                    }}
                >
                    Help achieve your financial goals and discover new revenue
                    streams.
                </ToolCard>

                <ToolCard
                    id={subnav[3].id}
                    title="Gamification"
                    video="/videos/methods/resources/gamification.mp4"
                    videoId="resources-gamification"
                    background="linear-gradient(270deg, #ECE0FD 0%, #FFDEE5 49.21%, #F8F8F8 100%)"
                    highlights={[
                        {
                            heading: 'award badges for milestones',
                            content:
                                'Make members feel accomplished for their efforts and incentivized to reach the next goal.',
                        },
                        {
                            heading: 'rank up in a leaderboard',
                            content:
                                'A community-wide leaderboard can highlight members for their high-level of activity.',
                        },
                        {
                            heading: 'gain experience points to level up',
                            content:
                                'Visually show progress to regular members for their continued activity.',
                        },
                    ]}
                >
                    Drive activity and reward members for their accomplishments.
                </ToolCard>
            </Section>

            <ThemeProvider defaultMode="dark">
                <MediaSection
                    id={subnav[4].id}
                    flipped
                    video="/videos/methods/resources/syncwpxf.mp4"
                    videoId="resources-syncwpxf"
                    title="Integrate WordPress with your forum"
                    bg="primaryBackground"
                >
                    <Paragraph>
                        Bridge the software gap between WordPress and XenForo
                        and integrate content with minimal tech overhead.
                    </Paragraph>
                    <Box pt="spacing" as="ul">
                        {[
                            {
                                title:
                                    'Supports sites being on different servers and domains',
                            },
                            {
                                title:
                                    'Comments synced between WordPress and XenForo                                ',
                            },
                            {
                                title:
                                    'Create threads for WordPress posts using XenForo API                                ',
                            },
                        ].map(highlight => (
                            <CheckListItem
                                key={highlight.title}
                                title={highlight.title}
                                as="li"
                            />
                        ))}
                    </Box>
                </MediaSection>
            </ThemeProvider>

            <PackageAddons id={subnav[5].id} />

            <ThemeProvider defaultMode="dark">
                <Section vertical="lg" wrap>
                    <MediaSectionContent
                        kind="h4"
                        title="For $1,000 annually, have access to both our Audentio Community Select and ThemeHouse products with support"
                    >
                        <Paragraph flex={1}>
                            The same community tools we implement for our
                            clients to support the best strategies of improving
                            user onboarding, engagement, experience, and more.
                        </Paragraph>
                    </MediaSectionContent>

                    <Box mt="spacing-lg">
                        <Button href="/" fill="white">
                            Subscribe
                        </Button>
                    </Box>

                    {/* <MediaSectionContent
                        kind="h4"
                        title="Have a tight budget? The rest of our add-ons are completely free"
                    >
                        <Paragraph flex={1}>
                            We have a robust digital product line that is
                            now at your disposal for quality of life
                            improvements and enhancing your community.
                        </Paragraph>
                        <Flex justify="flex-start" mt="spacing-lg">
                            <Button href="/" fill="white">
                                Download now
                            </Button>
                        </Flex>
                    </MediaSectionContent> */}
                </Section>
            </ThemeProvider>
        </Layout>
    );
}

export const query = graphql`
    query resourcesQuery {
        streaminterface: file(
            relativePath: { eq: "images/xfsoftware/stream-interface.png" }
        ) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
`;
