import React from 'react';
import { Box } from 'src/components/Box';
import {
    FeatureGrid,
    FeatureItem,
} from 'src/components/Landing/LandingFeatureGrid';
import { MediaGrid } from 'src/components/MediaSection';

export const ToolCard = ({
    title,
    highlights,
    children,
    video,
    videoId,
    videoPoster = null,
    ...rest
}) => {
    return (
        <Box
            p={{
                _: 'spacing-lg',
                lg: 'spacing-xl',
            }}
            rounded="radius"
            {...rest}
        >
            <MediaGrid
                flipped
                video={video}
                videoId={videoId}
                videoPoster={videoPoster}
                title={title}
                sectionProps={{
                    alignItems: {
                        _: 'flex-start',
                        lg: 'flex-start',
                    },
                }}
                mediaProps={{
                    maxWidth: { _: '100%', md: '50%', xl: '65%' },
                    pl: { _: 0, md: 'spacing', lg: 'spacing-xl' },
                }}
            >
                {children}
            </MediaGrid>

            <FeatureGrid
                pt={{
                    _: 'spacing-lg',
                    lg: 'spacing-xl',
                }}
            >
                {highlights.map((highlight, index) => (
                    <FeatureItem
                        key={index}
                        heading={highlight.heading}
                        content={highlight.content}
                    />
                ))}
            </FeatureGrid>
        </Box>
    );
};
